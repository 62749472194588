import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { listAdmin } from '../actions/adminAction';
import { updateTicketassignment } from '../actions/ticketAction';
import { Listbox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AssignModals = ({
  match,
  history,
  ticketid,
  open,
  setOpen,
  newTicket,
}) => {
  const dispatch = useDispatch();

  const ticketId = ticketid;

  const ticket = newTicket;

  const [keyword, setKeyword] = useState('');
  // const [complain, setComplain] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const adminList = useSelector((state) => state.adminList);
  const { loading: adminsLoading, admins } = adminList;

  const cancelButtonRef = useRef(null);

  const assignmentChangeHandler = (assignmen) => {
    dispatch(updateTicketassignment(assignmen, ticketId));
    setOpen(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  let searchAdmins = admins;
  const searchSubmitHandler = () => {
    searchAdmins = admins?.map((admin) => {
      if (admin.name.includes(keyword)) {
        return admin;
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      dispatch(listAdmin());
    }
  }, [history, userInfo, dispatch]);

  return (
    <>
      {!adminsLoading && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as='div'
            className='fixed z-10 inset-0 overflow-y-auto '
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 '>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <span
                className='hidden sm:inline-block sm:align-middle sm:h-screen'
                aria-hidden='true'
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                  <form onSubmit={submitHandler} className='min-h-full'>
                    <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                      <div className='sm:flex sm:items-start min-h-full'>
                        <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10'>
                          {/* <i className='fas fa-exclamation-circle fa-2x text-altaqnya-100'></i> */}
                        </div>
                        <div className='mt-12 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                          <Dialog.Title
                            as='h3'
                            className='text-lg leading-6 font-medium text-gray-900'
                          >
                            Assigned to:
                          </Dialog.Title>
                          <div className=''>
                            <div className='relative'>
                              <Listbox
                                value={ticket?.ticket_assignment}
                                onChange={assignmentChangeHandler}
                              >
                                {({ open }) => (
                                  <>
                                    {/* <Listbox.Label className='block text-sm font-medium text-gray-700'>
                          Assigned to
                        </Listbox.Label> */}
                                    <div>
                                      <div className='pt-2 relative mx-auto text-gray-600'>
                                        <input
                                          className='border-2 border-gray-300 bg-white h-10 px-5 pr-16 text-sm focus:outline-none'
                                          type='search'
                                          name='search'
                                          placeholder='Search'
                                          onChange={(e) =>
                                            setKeyword(e.target.value)
                                          }
                                        ></input>
                                        <button
                                          type='button'
                                          className=' right-0 top-0 mt-5 bg-altaqnya-100  h-10 px-3  rounded text-offWhite-100'
                                          onClick={searchSubmitHandler}
                                        >
                                          Search
                                        </button>
                                      </div>
                                    </div>
                                    <div className='mt-1 relative'>
                                      <Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                        <span className='flex items-center'>
                                          <span className='ml-3 block truncate'>
                                            {ticket?.ticket_assignment}
                                          </span>
                                        </span>
                                        <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                          <SelectorIcon
                                            className='h-5 w-5 text-gray-400'
                                            aria-hidden='true'
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave='transition ease-in duration-100'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                      >
                                        <Listbox.Options className='relative z-11 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm '>
                                          {searchAdmins.map((person) =>
                                            person != null ? (
                                              <Listbox.Option
                                                key={person?.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? 'text-white bg-indigo-600'
                                                      : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                  )
                                                }
                                                value={person?.name}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <div className='flex items-center'>
                                                      <span
                                                        Key={person?.name}
                                                        className={classNames(
                                                          selected
                                                            ? 'font-semibold'
                                                            : 'font-normal',
                                                          'ml-3 block truncate'
                                                        )}
                                                      >
                                                        {person?.name}
                                                      </span>
                                                    </div>

                                                    {selected ? (
                                                      <span
                                                        Key={person?.name}
                                                        className={classNames(
                                                          active
                                                            ? 'text-white'
                                                            : 'text-indigo-600',
                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className='h-5 w-5'
                                                          aria-hidden='true'
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                      <button
                        type='button'
                        className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default AssignModals;
