import {
  TICKET_CREATE_REQUEST,
  TICKET_CREATE_SUCCESS,
  TICKET_CREATE_FAIL,
  TICKET_CREATE_RESET,
  TICKET_LIST_REQUEST,
  TICKET_LIST_SUCCESS,
  TICKET_LIST_FAIL,
  TICKET_MY_LIST_REQUEST,
  TICKET_MY_LIST_SUCCESS,
  TICKET_MY_LIST_FAIL,
  TICKET_APPROVE_REQUEST,
  TICKET_APPROVE_SUCCESS,
  TICKET_APPROVE_FAIL,
  TICKET_DETAILS_REQUEST,
  TICKET_DETAILS_SUCCESS,
  TICKET_DETAILS_FAIL,
  TICKET_COMPLAIN_REQUEST,
  TICKET_COMPLAIN_SUCCESS,
  TICKET_COMPLAIN_FAIL,
  TICKET_STATUS_REQUEST,
  TICKET_STATUS_SUCCESS,
  TICKET_STATUS_FAIL,
  TICKET_ASSIGNMENT_REQUEST,
  TICKET_ASSIGNMENT_SUCCESS,
  TICKET_ASSIGNMENT_FAIL,
  TICKET_RESPONS_REQUEST,
  TICKET_RESPONS_SUCCESS,
  TICKET_RESPONS_FAIL,
  TICKET_PRIORITY_REQUEST,
  TICKET_PRIORITY_SUCCESS,
  TICKET_PRIORITY_FAIL,
  TICKET_LIST_REVERSE_REQUEST,
  TICKET_LIST_REVERSE_SUCCESS,
  TICKET_LIST_REVERSE_FAIL,
} from '../constants/ticketConstants';

export const ticketCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TICKET_CREATE_REQUEST:
      return { loading: true, success: false };
    case TICKET_CREATE_SUCCESS:
      return { loading: false, success: true, ticket: action.payload };
    case TICKET_CREATE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case TICKET_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const ticketListReducer = (state = { tickets: [] }, action) => {
  switch (action.type) {
    case TICKET_LIST_REQUEST:
      return { loading: true };
    case TICKET_LIST_SUCCESS:
      return {
        loading: false,
        tickets: action.payload.tickets,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };
    case TICKET_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const ticketMyListReducer = (state = { tickets: [] }, action) => {
  switch (action.type) {
    case TICKET_MY_LIST_REQUEST:
      return {
        loading: true,
        success: false,
      };
    case TICKET_MY_LIST_SUCCESS:
      return {
        loading: false,
        tickets: action.payload.tickets,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        success: true,
      };
    case TICKET_MY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

export const ticketDetailsReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TICKET_DETAILS_REQUEST:
      return { ...state, loading: true };
    case TICKET_DETAILS_SUCCESS:
      return { loading: false, ticket: action.payload };
    case TICKET_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case TICKET_APPROVE_REQUEST:
      return { loading: true };
    case TICKET_APPROVE_SUCCESS:
      return { loading: false, success: true };
    case TICKET_APPROVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketComplainReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TICKET_COMPLAIN_REQUEST:
      return { loading: true };
    case TICKET_COMPLAIN_SUCCESS:
      return { loading: false, success: true };
    case TICKET_COMPLAIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketStatusReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TICKET_STATUS_REQUEST:
      return { loading: true };
    case TICKET_STATUS_SUCCESS:
      return { loading: false, success: true };
    case TICKET_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketAssignmentReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TICKET_ASSIGNMENT_REQUEST:
      return { loading: true };
    case TICKET_ASSIGNMENT_SUCCESS:
      return { loading: false, success: true };
    case TICKET_ASSIGNMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketResponsReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TICKET_RESPONS_REQUEST:
      return { loading: true };
    case TICKET_RESPONS_SUCCESS:
      return { loading: false, success: true };
    case TICKET_RESPONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketPriorityReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TICKET_PRIORITY_REQUEST:
      return { loading: true };
    case TICKET_PRIORITY_SUCCESS:
      return { loading: false, success: true };
    case TICKET_PRIORITY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const ticketListReverseReducer = (state = { reverse }, action) => {
//   switch (action.type) {
//     case TICKET_LIST_REVERSE_REQUEST:
//       return { loading: true };
//     case TICKET_LIST_REVERSE_SUCCESS:
//       return {
//         loading: false,
//         reverse: action.payload.reverse,
//       };
//     case TICKET_LIST_REVERSE_FAIL:
//       return { loading: false, error: action.payload };

//     default:
//       return state;
//   }
// };

export const ticketListReverseReducer = (state = false, action) => {
  switch (action.type) {
    case TICKET_LIST_REVERSE_REQUEST:
      return { loading: true };
    case TICKET_LIST_REVERSE_SUCCESS:
      return { loading: false, reverse: action.payload };
    case TICKET_LIST_REVERSE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
