import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
// import Loader from "../components/Loader";
import { listTicket } from '../actions/ticketAction';
import { listReverseTicket } from '../actions/ticketAction';

import AllTicketsListPage from '../components/AllTicketsListPage';
import TicketListSearchBox from '../components/TicketListSearchBox';

import { Route } from 'react-router-dom';
// import LeaveListSearchBox from "../components/LeaveListSearchBox";
// import PaginateLeaveList from "../components/PaginateLeaveList";

const AllTicketsScreen = ({ match, history }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;
  const pageSize = match.params.pageSize || 20;
  const dispatch = useDispatch();

  const ticketList = useSelector((state) => state.ticketList);
  const { loading, tickets, page, pages, error } = ticketList;

  const ticketListReverse = useSelector((state) => state.ticketListReverse);
  const { reverse } = ticketListReverse;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handelReverse = () => {
    dispatch(listReverseTicket(reverse));
  };
  let ticketsFull = tickets;
  useEffect(() => {
    if (userInfo) {
      console.log(reverse);
      dispatch(listTicket(keyword, pageNumber, pageSize, reverse));
    } else {
      history.push('/login');
    }
  }, [dispatch, history, userInfo, keyword, pageNumber, pageSize, reverse]);

  return (
    <>
      {error ? (
        <Message className=' '>{error}</Message>
      ) : (
        (!loading && (
          <div className='flex flex-col bg-grayblack-300 py-16'>
            <div className='-my-2 overflow-x-auto '>
              <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-44 3xl:px-96'>
                <Route
                  render={({ history }) => (
                    <TicketListSearchBox history={history} />
                  )}
                />
                <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='px-6 py-3 text-left text-xs font-medium text-altaqnya-100 uppercase tracking-wider'
                        >
                          <button className='font-bold' onClick={handelReverse}>
                            Ticket No.
                          </button>
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                        >
                          Organization
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                        >
                          Subject Area
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                        >
                          Ticket Assignment
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                        >
                          Status
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                        >
                          Priority
                        </th>
                        <th scope='col' className='relative px-6 py-3'>
                          <span className='sr-only'>Complain</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {ticketsFull?.map((ticket) => (
                        <tr key={ticket._id}>
                          <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {ticket.ticket_number}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-gray-900'>
                              {ticket.organization}
                            </div>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-gray-900'>
                              {ticket.subjectArea}
                            </div>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-gray-900'>
                              {ticket.ticket_assignment}
                            </div>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {ticket.status === 'Open' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-white'>
                                {ticket.status}
                              </span>
                            ) : ticket.status === 'Closed' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-600 text-white'>
                                {ticket.status}
                              </span>
                            ) : ticket.status === 'OnHold' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-400 text-white'>
                                {ticket.status}
                              </span>
                            ) : ticket.status === 'InProgress' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-600 text-white'>
                                {ticket.status}
                              </span>
                            ) : (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                                {ticket.status}
                              </span>
                            )}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {ticket.priority === 'High' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-white'>
                                {ticket.priority}
                              </span>
                            ) : ticket.priority === 'Low' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-600 text-white'>
                                {ticket.priority}
                              </span>
                            ) : ticket.priority === 'Medium' ? (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-600 text-white'>
                                {ticket.priority}
                              </span>
                            ) : (
                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                                {ticket.priority}
                              </span>
                            )}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                            <button
                              onClick={() =>
                                history.push(`/details/${ticket._id}`)
                              }
                              className='text-red-600 hover:text-red-400'
                            >
                              Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className='w-full pt-6 rounded h-full '>
                  <AllTicketsListPage
                    pages={pages}
                    page={page}
                    keyword={keyword ? keyword : ''}
                    pageSize={pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        )) || <div className='h-screen'></div>
      )}
    </>
  );
};

export default AllTicketsScreen;
