const issues = [
  "Finesse",
  "Zoho",
  "Call Center (Call Block,IVR & Queue)",
  "Recording (Zoom)",
  "Ticket System",
  "Storage",
  "Network Security",
  "Data Center",
  "Virtualization",
  "Thuraya",
];

export default issues;
