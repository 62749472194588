import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import LoginScreen from './screens/LoginScreen';
import UseFormTest from './screens/UseFormTest';
import SubmitTicketScreen from './screens/SubmitTicketScreen';
import AllTicketsScreen from './screens/AllTicketsScreen';
import UserTicketsScreen from './screens/UserTicketsScreen';
import TicketScreen from './screens/TicketScreen';
import TicketDetailsScreen from './screens/TicketDetailsScreen';
import UserTicketDetailsScreen from './screens/UserTicketDetailsScreen';

function App() {
  return (
    <Router>
      <main className='flex flex-col min-h-screen h-full justify-between bg-grayblack-300'>
        <Header />
        <Route path='/register' component={UseFormTest} exact />

        <Route path='/ticket' component={SubmitTicketScreen} exact />
        <Route path='/' component={SubmitTicketScreen} exact />
        <Route path='/alltickets' component={AllTicketsScreen} exact />
        <Route
          path='/alltickets/page/:pageNumber/pageSize/:pageSize'
          component={AllTicketsScreen}
          exact
        />
        <Route
          path='/alltickets/search/:keyword'
          component={AllTicketsScreen}
          exact
        />
        <Route
          path='/alltickets/search/:keyword/page/:pageNumber/pageSize/:pageSize'
          component={AllTicketsScreen}
          exact
        />
        <Route path='/tickets' component={UserTicketsScreen} exact />
        <Route
          path='/tickets/page/:pageNumber/pageSize/:pageSize'
          component={UserTicketsScreen}
          exact
        />
        <Route
          path='/tickets/search/:keyword'
          component={UserTicketsScreen}
          exact
        />
        <Route
          path='/tickets/search/:keyword/page/:pageNumber/pageSize/:pageSize'
          component={UserTicketsScreen}
          exact
        />

        <Route path='/login' component={LoginScreen} exact />
        <Route path='/ticketID/:id' component={TicketScreen} />
        <Route path='/details/:id' component={TicketDetailsScreen} />
        <Route path='/userdetails/:id' component={UserTicketDetailsScreen} />
        <footer>
          <Footer />
        </footer>
      </main>
    </Router>
  );
}

export default App;
