import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { createTicket } from '../actions/ticketAction';
import Message from '../components/Message';

import issues from '../data/IssuesList';
import SubmitModal from '../components/SubmitModal';

const schema = yup.object().shape({
  subject: yup.string().required(),
  message: yup.string().required(),
  subjectArea: yup.string().required(),
});

const SubmitTicketScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  const ticketCreate = useSelector((state) => state.ticketCreate);
  const { success, error: ticketError } = ticketCreate;

  const redirect = '/login';

  useEffect(() => {
    if (!userInfo) {
      history.push(redirect);
    }
    if (success) {
      setOpen(true);
      setTimeout(function () {
        window.location.reload(false);
      }, 2.0 * 1000);
    }
  }, [history, userInfo, redirect, dispatch, success]);

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    dispatch(createTicket(data));
    if (success) {
      setOpen(true);
      setTimeout(function () {
        window.location.reload(false);
      }, 2.0 * 1000);
    }
  });

  return (
    <div className='md:h-full h-full w-full bg-cool-200 '>
      {<SubmitModal open={open} setOpen={setOpen} />}
      <div className=' flex flex-col md:py-20 py-20 container md:px-16 px-3 max-w-3xl'>
        {error && <Message className='py-8'>{error}</Message>}
        {ticketError && <Message className='py-8'>{ticketError}</Message>}
        <div className='flex justify-center pb-2 text-gray-500'>
          <i className='fas fa-life-ring fa-3x	'></i>
          <h1 className=' px-2 text-5xl font-bold'>Support</h1>
        </div>
        <div className='flex justify-center py-10'>
          <p>
            We're available 24/7. Submit a ticket and we'll be more than happy
            to help!
          </p>
        </div>
        <form action='' className='space-y-6' onSubmit={onSubmit}>
          <div>
            <label htmlFor='' className='  text-gray-600 block'>
              <i className='fas fa-question-circle'></i>
              <span className='px-1'>Subject Area</span>
              <select
                name='area'
                type='area'
                className='w-full p-2 border border-gray-300 rounded mt-1 bg-grayblack-300  '
                {...register('subjectArea')}
              >
                <option className='w-full p-2 border border-gray-300 rounded text-lg'>
                  select subject area
                </option>
                {issues.map((issue, index) => {
                  return (
                    <option
                      key={index}
                      className='w-full p-2 border border-gray-300 rounded text-lg'
                    >
                      {issue}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div>
            <label htmlFor='' className='  text-gray-600 block'>
              <i className='fas fa-envelope'></i>
              <span className='px-1'>Subject</span>
            </label>
            <input
              name='subject'
              {...register('subject')}
              type='text'
              className='w-full p-2 border border-gray-300 rounded mt-1 bg-grayblack-300'
              placeholder='subject of your concern.'
            />
          </div>
          <div>
            <label htmlFor='' className='  text-gray-600 block'>
              <i className='fas fa-envelope-open-text'></i>
              <span className='px-1'>Message</span>
              <textarea
                name='message'
                {...register('message')}
                className='form-textarea block  w-full p-2 border border-gray-300 rounded mt-1 bg-grayblack-300'
                rows='3'
                placeholder='describe your issue.'
              ></textarea>
            </label>
          </div>
          <div className=' '>
            <button
              type='submit'
              className='w-full bg-altaqnya-100 text-white font-medium px-4 py-2 rounded hover:bg-altaqnya-200'
            >
              <i className='fas fa-life-ring px-1'></i>
              Submit Ticket
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubmitTicketScreen;
