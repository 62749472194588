import {
  TICKET_CREATE_REQUEST,
  TICKET_CREATE_SUCCESS,
  TICKET_CREATE_FAIL,
  TICKET_LIST_REQUEST,
  TICKET_LIST_SUCCESS,
  TICKET_LIST_FAIL,
  TICKET_MY_LIST_REQUEST,
  TICKET_MY_LIST_SUCCESS,
  TICKET_MY_LIST_FAIL,
  TICKET_APPROVE_REQUEST,
  TICKET_APPROVE_SUCCESS,
  TICKET_APPROVE_FAIL,
  TICKET_DETAILS_REQUEST,
  TICKET_DETAILS_SUCCESS,
  TICKET_DETAILS_FAIL,
  TICKET_COMPLAIN_REQUEST,
  TICKET_COMPLAIN_SUCCESS,
  TICKET_COMPLAIN_FAIL,
  TICKET_STATUS_REQUEST,
  TICKET_STATUS_SUCCESS,
  TICKET_STATUS_FAIL,
  TICKET_RESPONS_REQUEST,
  TICKET_RESPONS_SUCCESS,
  TICKET_RESPONS_FAIL,
  TICKET_PRIORITY_REQUEST,
  TICKET_PRIORITY_SUCCESS,
  TICKET_PRIORITY_FAIL,
  TICKET_LIST_REVERSE_REQUEST,
  TICKET_LIST_REVERSE_SUCCESS,
  TICKET_LIST_REVERSE_FAIL,
} from '../constants/ticketConstants';

import axios from '../axios/index';

export const createTicket =
  ({ subject, message, subjectArea }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/tickets`,
        {
          userInfo,
          subject,
          message,
          subjectArea,
        },
        config
      );

      // dispatch({
      //   type: TICKET_CREATE_RESET,
      // });
      dispatch({ type: TICKET_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TICKET_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTicket =
  (keyword = '', pageNumber = '', pageSize = '', reverse) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/tickets?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}&reverse=${reverse}`,
        config
      );

      dispatch({
        type: TICKET_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listMyTicket =
  (keyword = '', pageNumber = '', pageSize = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_MY_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/tickets/mytickets?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}&id=${userInfo.id}`,
        config
      );

      dispatch({
        type: TICKET_MY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_MY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const approveTicket = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_APPROVE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/ticket/${id}`, config);

    dispatch({
      type: TICKET_APPROVE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TICKET_APPROVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTicketDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/tickets/${id}`, config);

    dispatch({
      type: TICKET_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TICKET_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTicketComplain =
  (complain, ticketID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_COMPLAIN_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        '/tickets/complain',
        {
          complain,
          ticketID,
        },
        config
      );
      dispatch({
        type: TICKET_COMPLAIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_COMPLAIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTicketStatus =
  (statusz, ticketID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        '/tickets/status',
        {
          statusz,
          ticketID,
        },
        config
      );
      dispatch({
        type: TICKET_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTicketassignment =
  (assignmen, ticketID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_STATUS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        '/tickets/assignment',
        {
          assignmen,
          ticketID,
        },
        config
      );
      dispatch({
        type: TICKET_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTicketRespons =
  (responst, ticketID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_RESPONS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        '/tickets/respons',
        {
          responst,
          ticketID,
        },
        config
      );
      dispatch({
        type: TICKET_RESPONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_RESPONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTicketPriority =
  (priorityz, ticketID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TICKET_PRIORITY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        '/tickets/priority',
        {
          priorityz,
          ticketID,
        },
        config
      );
      dispatch({
        type: TICKET_PRIORITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TICKET_PRIORITY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listReverseTicket = (reversex) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TICKET_LIST_REVERSE_REQUEST,
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     authorization: `Bearer ${userInfo.token}`,
    //   },
    // };

    // const {
    //   ticketListReverse: { reverse },
    // } = getState();

    const data = !reversex;
    dispatch({
      type: TICKET_LIST_REVERSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TICKET_LIST_REVERSE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
