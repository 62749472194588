import React, { useState } from 'react';

const UserTicketListSearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('');
  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/tickets/search/${keyword}`);
    } else {
      history.push('/tickets');
    }
  };
  return (
    <form onSubmit={submitHandler} className='w-full'>
      <div className='flex justify-center w-full py-6'>
        <input
          type='text'
          className='w-4/5 p-1 rounded py-2 pl-4'
          name='q'
          onChange={(e) => setKeyword(e.target.value)}
          placeholder='Search'
        ></input>
        <button
          type='submit'
          variant='outline-success'
          className='ml-2 bg-altaqnya-100 text-white px-4 rounded'
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default UserTicketListSearchBox;
