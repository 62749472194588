import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketDetails } from '../actions/ticketAction';
import statuss from '../data/statusList';
import { updateTicketStatus } from '../actions/ticketAction';
import { updateTicketRespons } from '../actions/ticketAction';

const TicketDetailsScreen = ({ match, history }) => {
  const ticketId = match.params.id;
  const dispatch = useDispatch();

  const [responst, setResponst] = useState('');

  const ticketDetails = useSelector((state) => state.ticketDetails);
  const { loading, ticket } = ticketDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const ticketStatus = useSelector((state) => state.ticketStatus);
  const { loading: statusLoading, success } = ticketStatus;

  const ticketRespons = useSelector((state) => state.ticketRespons);
  const { success: responsSuccess } = ticketRespons;

  const statusChangeHandler = (statusz) => {
    dispatch(updateTicketStatus(statusz, ticketId));
  };

  const submittt = (e) => {
    e.preventDefault();
  };

  const submitttz = (e) => {
    e.preventDefault();
    dispatch(updateTicketRespons(responst, ticketId));
  };

  let timeNow = new Date();
  timeNow.toLocaleDateString('en-GB', { timeZone: 'Africa/Tripoli' });
  timeNow = timeNow.getTime();

  let timeofticket3 = new Date(ticket?.createdAt);
  timeofticket3 = timeofticket3.getTime();
  let hours = Math.floor((timeNow - timeofticket3) / (1000 * 60 * 60));
  const diff = () => {
    return hours;
  };
  useEffect(() => {
    if (userInfo) {
      dispatch(getTicketDetails(ticketId));
    }
  }, [dispatch, history, ticketId, success, responsSuccess, userInfo]);

  return (
    <>
      {!loading && !statusLoading && (
        <div className='bg-white shadow  sm:rounded-lg py-0 mx-4 lg:mx-52 my-8 3xl:mx-96'>
          <div className='px-4 py-5 sm:px-6 '>
            <div className='lg:flex lg:justify-between'>
              <h3 className='text-lg leading-6 font-medium text-gray-900 my-auto'>
                Ticket #{ticket.ticket_number}
              </h3>
              <div className='flex my-auto'>
                <div className=' max-w-2xl text-sm text-gray-500 w-full'>
                  <form onSubmit={submittt}>
                    <div>
                      <label htmlFor='' className='  text-gray-600 block'>
                        <select
                          name='status'
                          className='w-6/8 p-2 border border-gray-300 rounded  bg-grayblack-300  '
                          onChange={(e) => statusChangeHandler(e.target.value)}
                        >
                          <option className='w-full p-2 border border-gray-300 rounded text-lg'>
                            {ticket.status}
                          </option>
                          {statuss.map((stat, index) => {
                            return stat !== ticket.status ? (
                              <option
                                key={index}
                                className='w-full p-2 border border-gray-300 rounded text-lg'
                              >
                                {stat}
                              </option>
                            ) : (
                              <></>
                            );
                          })}
                        </select>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='border-t border-gray-200'>
            <dl>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Organization
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {ticket.organization}
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Subject Area
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {ticket.subjectArea}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Email address
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {ticket.email}
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>phone</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {ticket?.phone}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>time</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {diff() + ` Hour`}
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>Subject</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {ticket.subject}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>Message</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  {ticket.message}
                  {ticket.description}
                </dd>
              </div>
              {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Complain</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {ticket.complain}
                </dd>
              </div> */}
              {ticket.respons ? (
                !Array.isArray(ticket.respons) ? (
                  <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Comment
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {ticket.respons}
                    </dd>
                  </div>
                ) : (
                  <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                    <dt className='text-sm font-medium text-gray-500'>
                      Comment
                    </dt>
                    <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                      {ticket.respons[ticket.respons.length - 1]}
                    </dd>
                  </div>
                )
              ) : (
                <></>
              )}
              <form onSubmit={submitttz}>
                <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <label className='text-sm font-medium text-gray-500'>
                    <dt className='text-sm font-medium text-gray-500'>
                      <span>Comment</span>
                    </dt>
                  </label>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 '>
                    <textarea
                      className='w-5/6 bg-gray-50 p-2'
                      name='message'
                      rows='4'
                      placeholder='Comment on the ticket.'
                      onChange={(e) => setResponst(e.target.value)}
                    ></textarea>
                    <button
                      className='pl-5 text-xl text-altaqnya-100 hover:text-altaqnya-200'
                      type='submit'
                    >
                      Comment
                    </button>
                  </dd>
                </div>
              </form>

              {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Attachments
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul
                    role="list"
                    className="border border-gray-200 rounded-md divide-y divide-gray-200"
                  >
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                       
                        <span className="ml-2 flex-1 w-0 truncate">
                          resume_back_end_developer.pdf
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Download
                        </a>
                      </div>
                    </li>
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                       
                        <span className="ml-2 flex-1 w-0 truncate">
                          coverletter_back_end_developer.pdf
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default TicketDetailsScreen;
