import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createOrderReducer } from './reducers/orderReducers';
import {
  ticketCreateReducer,
  ticketListReducer,
  ticketMyListReducer,
  ticketApproveReducer,
  ticketDetailsReducer,
  ticketComplainReducer,
  ticketStatusReducer,
  ticketAssignmentReducer,
  ticketResponsReducer,
  ticketPriorityReducer,
  ticketListReverseReducer,
} from './reducers/ticketReducers';
import { adminListReducer } from './reducers/adminReducers';
import { userLoginReducer, userRegisterReducer } from './reducers/userReducers';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  createOrder: createOrderReducer,
  ticketCreate: ticketCreateReducer,
  ticketList: ticketListReducer,
  ticketMyList: ticketMyListReducer,
  ticketApprove: ticketApproveReducer,
  ticketDetails: ticketDetailsReducer,
  ticketComplain: ticketComplainReducer,
  ticketStatus: ticketStatusReducer,
  adminList: adminListReducer,
  ticketAssignment: ticketAssignmentReducer,
  ticketRespons: ticketResponsReducer,
  ticketPriority: ticketPriorityReducer,
  ticketListReverse: ticketListReverseReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;

// userDetailsReducer,
// userUpdatePasswordReducer,
// userListReducer,
// userDeleteReducer,
// userUpdateReducer,
// userApprovalReducer,
// userDetails: userDetailsReducer,
// userUpdatePassword: userUpdatePasswordReducer,
// userList: userListReducer,
// userDelete: userDeleteReducer,
// userApproval: userApprovalReducer,
// userUpdate: userUpdateReducer,
