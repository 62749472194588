import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { registerUser } from '../actions/userAction';
import Loader from '../components/Loader';
import Message from '../components/Message';
import issues from '../data/IssuesList';

const schema = yup.object().shape({
  firstName: yup.string().required('first name is a required field'),
  lastName: yup.string().required('last name is a required field'),
  organization: yup.string().required('organization is a required field'),
  userName: yup
    .string()
    .required('Username is a required field')
    .min(4, 'Username must be at least 4 characters'),
  password: yup.string().required('password is a required field'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null]),
  phone: yup
    .number('phone is a number')
    .typeError('phone is a required field')
    .required('phone is a required field'),
  email: yup.string().email().required('email is a required field'),
  department: yup.string(),
  employe: yup.boolean(),
});

const UseFormTest = () => {
  const dispatch = useDispatch();

  const [isEmploye, setIsEmploye] = useState(false);

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  let history = useHistory();

  useEffect(() => {
    if (userInfo) {
      history.push('/login');
    }
  }, [history, userInfo]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    dispatch(registerUser(data));

    // if (!error) {
    //   history.push("/login");
    // }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div className='h-full w-full bg-cool-200 md:pt-14'>
      {loading && <Loader />}
      <div className='container max-w-3xl py-10 md:px-16 px-3'>
        {error && (
          <div className=''>
            {' '}
            <Message>{error}</Message>
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-rows-4 gap-4 '>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4 '>
              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-user'></i>
                  <span className='px-1'>First Name</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='text'
                  name='firstName'
                  placeholder='your first name'
                  {...register('firstName')}
                ></input>
                <p className=' opacity-50 text-s'>
                  {errors?.firstName?.message}
                </p>
              </div>

              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-users'></i>
                  <span className='px-1'>Last Name</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='text'
                  name='lastName'
                  placeholder='your last name'
                  {...register('lastName')}
                ></input>
                <p className=' opacity-50 text-s'>
                  {errors?.lastName?.message}
                </p>
              </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-building'></i>
                  <span className='px-1'>Organization</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='text'
                  placeholder="your organization's name"
                  {...register('organization')}
                ></input>
                <p className=' opacity-50 text-s'>
                  {errors?.organization?.message}
                </p>
              </div>

              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-user-shield'></i>

                  <span className='px-1'>User Name</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='text'
                  placeholder='username for signing in'
                  {...register('userName')}
                ></input>
                <p className=' opacity-50 text-s'>
                  {errors?.userName?.message}
                </p>
              </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-user-lock'></i>
                  <span className='px-1'>Password</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='password'
                  placeholder='password for signing in'
                  {...register('password')}
                ></input>
                <p className=' opacity-50 text-s'>
                  {errors?.password?.message}
                </p>
              </div>

              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-user-lock'></i>
                  <span className='px-1'>Confirm Password</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='password'
                  placeholder='re-enter your password'
                  {...register('confirmPassword')}
                ></input>
                <p className=' opacity-50 text-s'>
                  {errors.confirmPassword && 'Passwords Shoud match!'}
                </p>
              </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-mobile'></i>
                  <span className='px-1'>Phone</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='text'
                  placeholder='e.g. 09xxxxxxxx'
                  {...register('phone')}
                ></input>
                <p className=' opacity-50 text-s'>{errors?.phone?.message}</p>
              </div>

              <div>
                <label className='text-sm font-bold text-gray-600 block'>
                  <i className='fas fa-envelope-open-text'></i>
                  <span className='px-1'>Email</span>
                </label>
                <input
                  className='w-full p-2 border border-gray-300 rounded mt-1'
                  type='email'
                  placeholder='e.g. email@example.com'
                  {...register('email')}
                ></input>
                <p className=' opacity-50 text-s'>{errors?.email?.message}</p>
              </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
              <div>
                <div className='flex items-start'>
                  <div className='flex items-center h-5'>
                    <input
                      id='employe'
                      name='employe'
                      type='checkbox'
                      className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      onClick={() => setIsEmploye(true)}
                      {...register('employe')}
                    />
                  </div>
                  <div className='ml-3 text-sm'>
                    <label
                      htmlFor='employe'
                      className='font-medium text-gray-700'
                    >
                      Altaqnya Employe
                    </label>
                    <p className='opacity-50 text-s'>
                      {errors?.employe?.message}
                    </p>
                  </div>
                </div>
              </div>
              {isEmploye && (
                <div>
                  <label className='text-sm font-bold text-gray-600 block'>
                    <i className='fas fa-microchip'></i>
                    <span className='px-1'>Department</span>
                    <select
                      name='department'
                      type='department'
                      className='w-full p-2 border border-gray-300 rounded mt-1 bg-grayblack-300  '
                      {...register('department')}
                    >
                      <option className='w-full p-2 border border-gray-300 rounded text-lg'>
                        Department
                      </option>
                      {issues.map((issue, index) => {
                        return (
                          <option
                            key={index}
                            className='w-full p-2 border border-gray-300 rounded text-lg'
                          >
                            {issue}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className='pt-6'>
            <button
              type='submit'
              className='w-full bg-altaqnya-100 text-white font-medium px-4 py-2 rounded hover:bg-altaqnya-200'
            >
              Sign Up
            </button>
          </div>
        </form>
        <div className='flex justify-center pt-3'>
          {`Already have an account? `}
          <a
            className='text-altaqnya-100 hover:underline hover:text-altaqnya-100 '
            href='/login'
          >
            &nbsp;Sign in
          </a>
        </div>
      </div>
    </div>
  );
};

export default UseFormTest;
