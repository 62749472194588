import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const Paginatealltickets = ({ page, keyword = '', pageSize, pages }) => {
  return (
    <div className=' px-4 py-3 w-full  border-t border-gray-200 sm:px-6 '>
      <div className='w-full flex mx-auto'>
        {/* <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {page * pageSize - pageSize + 1
                ? page * pageSize - pageSize + 1
                : 0}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {page * pageSize ? page * pageSize : 0}
            </span>{" "}
            of{" "}
            <span className="font-medium">
              {pages * pageSize ? pages * pageSize : 0}
            </span>{" "}
            results
          </p>
        </div> */}
        <div className='flex mx-auto'>
          <nav
            className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
            aria-label='Pagination'
          >
            <a
              href={
                page - 1 <= 0
                  ? keyword
                    ? `/alltickets/search/${keyword}/page/${page}/pageSize/${pageSize}`
                    : `/alltickets/page/${page}/pageSize/${pageSize}`
                  : keyword
                  ? `/alltickets/search/${keyword}/page/${
                      page - 1
                    }/pageSize/${pageSize}`
                  : `/alltickets/page/${page - 1}/pageSize/${pageSize}`
              }
              className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
            >
              <span className='sr-only'>Previous</span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </a>

            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}

            {[...Array(pages).keys()].map((x) =>
              x + 1 >= 1 && x + 1 === page - 1 ? (
                page === x ? (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                ) : (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                )
              ) : x < pages && x + 1 === page + 1 ? (
                page === x ? (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                ) : (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                )
              ) : (
                x + 1 === page &&
                (page === x ? (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                ) : page === x ? (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                ) : (
                  <a
                    key={x + 1}
                    href={
                      keyword
                        ? `/alltickets/search/${keyword}/page/${
                            x + 1
                          }/pageSize/${pageSize}`
                        : `/alltickets/page/${x + 1}/pageSize/${pageSize}`
                    }
                    aria-current='page'
                    className='z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                  >
                    {x + 1}
                  </a>
                ))
              )
            )}

            <a
              href={
                page + 1 > pages
                  ? keyword
                    ? `/alltickets/search/${keyword}/page/${page}/pageSize/${pageSize}`
                    : `/alltickets/page/${page}/pageSize/${pageSize}`
                  : keyword
                  ? `/alltickets/search/${keyword}/page/${
                      page + 1
                    }/pageSize/${pageSize}`
                  : `/alltickets/page/${page + 1}/pageSize/${pageSize}`
              }
              className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
            >
              <span className='sr-only'>Next</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Paginatealltickets;
