import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import Loader from "../components/Loader";
import { getTicketDetails } from "../actions/ticketAction";

const TicketScreen = ({ match, history }) => {
  const ticketId = match.params.id;
  const dispatch = useDispatch();

  const ticketDetails = useSelector((state) => state.ticketDetails);
  const { loading, ticket } = ticketDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(getTicketDetails(ticketId));
    }
  }, [dispatch, history, ticketId, userInfo]);

  return (
    <>
      {!loading && (
        <div className="min-h-screen bg-grayblack-300 py-6 flex flex-col justify-center sm:py-12">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 bg-clip-padding bg-opacity-60 border border-gray-200">
              <div className="max-w-md mx-auto">
                <div>
                  <h1 className="text-3xl font-bold flex justify-center">
                    #{ticket.ticket_number}
                  </h1>
                </div>
                <div className="divide-y divide-gray-200">
                  <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <p className="text-xl font-bold">{ticket.subject}</p>
                    <ul className="list-disc space-y-2">
                      <li className="flex items-start">
                        <span className="h-6 flex items-center sm:h-7">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-cyan-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          ></svg>
                        </span>
                        <p className="ml-2">
                          {ticket.message}
                          {ticket.description}
                        </p>
                      </li>
                    </ul>
                    <p className="text-xl font-bold">Complain</p>
                    <ul className="list-disc space-y-2">
                      <li className="flex items-start">
                        <span className="h-6 flex items-center sm:h-7">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-cyan-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          ></svg>
                        </span>
                        <p className="ml-2">{ticket.complain}</p>
                      </li>
                    </ul>
                  </div>
                  <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                    {/* <p>
                  See me on{" "}
                  <a
                    className="font-bold text-blue-700"
                    href="https://www.linkedin.com/in/ferdianar/"
                  >
                    {" "}
                    LinkedIn{" "}
                  </a>
                </p>
                <p>
                  <a
                    href="https://tailwindcss.com/docs"
                    className="text-cyan-600 hover:text-cyan-700"
                  >
                    {" "}
                    Read the docs &rarr;{" "}
                  </a>
                </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TicketScreen;
