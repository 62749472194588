import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { login } from '../actions/userAction';
import Message from '../components/Message';

const schema = yup.object().shape({
  password: yup.string().required(),
  email: yup.string().email().required(),
});

const LoginScreen = ({ location, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  const redirect = '/';

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const {
    register,

    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    dispatch(login(data));
  });

  return (
    <div className='md:h-full h-full w-full bg-cool-200 '>
      <div className=' flex flex-col md:py-28 py-18 container md:px-16 px-3 max-w-3xl'>
        {error && <Message className='pb-8'>{error}</Message>}
        <form action='' className='space-y-6' onSubmit={onSubmit}>
          <div>
            <label htmlFor='' className='text-sm font-bold text-gray-600 block'>
              <i className='fas fa-user-shield'></i>
              <span className='px-1'>Email</span>
            </label>
            <input
              name='email'
              {...register('email')}
              type='email'
              className='w-full p-2 border border-gray-300 rounded mt-1'
            />
          </div>
          <div>
            <label htmlFor='' className='text-sm font-bold text-gray-600 block'>
              <i className='fas fa-user-lock'></i>
              <span className='px-1'>Password</span>
            </label>
            <input
              name='password'
              {...register('password')}
              type='password'
              className='w-full p-2 border border-gray-300 rounded mt-1'
            />
          </div>
          <div className=' '>
            <button
              type='submit'
              className='w-full bg-altaqnya-100 text-white font-medium px-4 py-2 rounded hover:bg-altaqnya-200'
            >
              Sign In
            </button>
          </div>
        </form>
        <div className='flex justify-center pt-2'>
          {`Don't have an account? `}
          <a
            className='text-altaqnya-100 hover:underline hover:text-altaqnya-100'
            href='/register'
          >
            &nbsp;Sign up
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
