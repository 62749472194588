import React from "react";
import Photo from "../image/contactUsTaqnya.jpg";
import Logo from "../image/Logo_white.png";

const Footer = () => {
  return (
    <div
      className=" bg-black h-full bg-cover bg-center bg-no-repeat md:h-96 "
      style={{
        backgroundImage: `url(${Photo})`,
      }}
      id="contact"
    >
      <div className="bg-black bg-opacity-60 md:flex md:content-center justify-center grid md:grid-rows-2 md:gap-4 grid-rows-1 gap-0 h-full">
        <div className=" row-span-1 px-2 ">
          <img src={Logo} className=" max-h-96" alt="Logo" />
        </div>
        <div className=" text-white text-center row-span-1 md:pt-10 px-2 md:mb-10 mb-16">
          <h1 className="text-5xl font-bold my-10">Get in Touch!</h1>

          <h1 className="text-5xl font-bold my-10">Contact an Expert</h1>

          <div className="">
            <a
              target="_blank"
              className="py-2 px-4 max-h-10 text-white bg-altaqnya-100 rounded hover:bg-altaqnya-200 transition duration-300"
              rel="noreferrer"
              href="https://altaqnya.com.ly/contact"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
