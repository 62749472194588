import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../image/taqnya-logo[399].svg';

import { logout } from '../actions/userAction';
import { decode } from 'jsonwebtoken';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [check, setCheck] = useState(false);

  const mobileMenuHandler = () => {
    setCheck((prevCheck) => !prevCheck);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    const token = userInfo?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) dispatch(logout());
    }
  }, [location, dispatch, userInfo]);

  return (
    <nav className='text-primery-300 bg-grayblack-300 px-4'>
      <div className='flex justify-around space-x-1 pt-3 '>
        <div>
          <a href='/' className='py-4 px-2'>
            <img src={Logo} className='w-44' alt='taqnya logo' />
          </a>
        </div>
        <div className='flex items-center'>
          {userInfo && (
            <a
              className='py-4 px-3 hidden lg:flex hover:text-altaqnya-100 transition duration-300'
              href='/ticket'
            >
              Submit Ticket
            </a>
          )}

          {userInfo && userInfo.isSuper && (
            <a
              className='py-4 px-3 hidden lg:flex hover:text-altaqnya-100 transition duration-300'
              href='/alltickets'
            >
              All Tickets
            </a>
          )}
          {userInfo && userInfo.isAdmin && (
            <a
              className='py-4 px-3 hidden lg:flex hover:text-altaqnya-100 transition duration-300'
              href='/tickets'
              rel='noreferrer'
            >
              My Tickets
            </a>
          )}
        </div>

        {userInfo ? (
          <div className='flex items-center space-x-1'>
            <button
              className='py-2 px-8 hidden lg:flex text-white bg-altaqnya-100 rounded hover:bg-altaqnya-200  transition duration-300'
              onClick={logoutHandler}
            >
              Log Out
            </button>
            <div className='flex lg:hidden items-center text-primery-100 '>
              <button
                className='mobile-menu-button '
                onClick={mobileMenuHandler}
              >
                <i className='fas fa-bars fa-2x'></i>
              </button>
            </div>
          </div>
        ) : (
          <div className='flex items-center space-x-4'>
            <a
              className='py-2 px-3 hidden lg:flex text-white bg-altaqnya-100 rounded hover:bg-altaqnya-200  transition duration-300'
              href='/login'
            >
              Sign In
            </a>
            <a
              className='py-2 px-3 hidden lg:flex text-white bg-altaqnya-100 rounded hover:bg-altaqnya-200  transition duration-300'
              href='/register'
            >
              Sign Up
            </a>

            <div className='flex lg:hidden items-center text-primery-100 '>
              <button
                className='mobile-menu-button '
                onClick={mobileMenuHandler}
              >
                <i className='fas fa-bars fa-2x'></i>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* mobile menu */}
      <div className='mobile-menu px-2 lg:hidden'>
        {check && userInfo && (
          <div className='grid grid-rows-4 text-center justify-center'>
            {userInfo && (
              <a
                className='py-1 px-3   hover:text-altaqnya-100 transition duration-300'
                href='/ticket'
              >
                Submit Ticket
              </a>
            )}

            {userInfo && userInfo.isSuper && (
              <a
                className='py-1 px-3   hover:text-altaqnya-100 transition duration-300'
                href='/alltickets'
              >
                All Tickets
              </a>
            )}

            {userInfo && userInfo.isAdmin && (
              <a
                className=' py-1 px-3   hover:text-altaqnya-100 transition duration-300'
                href='/tickets'
                rel='noreferrer'
              >
                My Tickets
              </a>
            )}

            <button
              className=' py-1 px-8   hover:text-altaqnya-100 transition duration-300'
              onClick={logoutHandler}
            >
              Log Out
            </button>
          </div>
        )}
        {check && !userInfo && (
          <div className='grid grid-rows-4 text-center justify-center '>
            <a
              className='block py-2 px-4 text-sm hover:underline hover:text-altaqnya-100 transition duration-300'
              href='/login'
            >
              Sign In
            </a>

            {userInfo && (
              <a
                className='block py-2 px-4 text-sm hover:underline hover:text-altaqnya-100 transition duration-300'
                href='/ticket'
              >
                Submit Ticket
              </a>
            )}

            {userInfo && userInfo.isSuper && (
              <a
                className='block py-2 px-4 text-sm hover:underline hover:text-altaqnya-100 transition duration-300'
                href='/alltickets'
              >
                All Tickets
              </a>
            )}

            {userInfo && userInfo.isAdmin && (
              <a
                className='block py-2 px-4 text-sm hover:underline hover:text-altaqnya-100 transition duration-300'
                rel='noreferrer'
                href='/tickets'
              >
                My Tickets
              </a>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
