export const TICKET_CREATE_REQUEST = 'TICKET_CREATE_REQUSET';
export const TICKET_CREATE_SUCCESS = 'TICKET_CREATE_SUCCESS';
export const TICKET_CREATE_FAIL = 'TICKET_CREATE_FAIL';
export const TICKET_CREATE_RESET = 'TICKET_CREATE_RESET';

export const TICKET_LIST_REQUEST = 'TICKET_LIST_REQUEST';
export const TICKET_LIST_SUCCESS = 'TICKET_LIST_SUCCESS';
export const TICKET_LIST_FAIL = 'TICKET_LIST_FAIL';
export const TICKET_LIST_RESET = 'TICKET_LIST_RESET';

export const TICKET_MY_LIST_REQUEST = 'TICKET_MY_LIST_REQUEST';
export const TICKET_MY_LIST_SUCCESS = 'TICKET_MY_LIST_SUCCESS';
export const TICKET_MY_LIST_FAIL = 'TICKET_MY_LIST_FAIL';

export const TICKET_APPROVE_REQUEST = 'TICKET_APPROVE_REQUEST';
export const TICKET_APPROVE_SUCCESS = 'TICKET_APPROVE_SUCCESS';
export const TICKET_APPROVE_FAIL = 'TICKET_APPROVE_FAIL';

export const TICKET_DETAILS_REQUEST = 'TICKET_DETAILS_REQUSET';
export const TICKET_DETAILS_SUCCESS = 'TICKET_DETAILS_SUCCESS';
export const TICKET_DETAILS_FAIL = 'TICKET_DETAILS_FAIL';
export const TICKET_DETAILS_RESET = 'TICKET_DETAILS_RESET';

export const TICKET_COMPLAIN_REQUEST = 'TICKET_COMPLAIN_REQUSET';
export const TICKET_COMPLAIN_SUCCESS = 'TICKET_COMPLAIN_SUCCESS';
export const TICKET_COMPLAIN_FAIL = 'TICKET_COMPLAIN_FAIL';

export const TICKET_STATUS_REQUEST = 'TICKET_STATUS_REQUSET';
export const TICKET_STATUS_SUCCESS = 'TICKET_STATUS_SUCCESS';
export const TICKET_STATUS_FAIL = 'TICKET_STATUS_FAIL';

export const TICKET_ASSIGNMENT_REQUEST = 'TICKET_ASSIGNMENT_REQUSET';
export const TICKET_ASSIGNMENT_SUCCESS = 'TICKET_ASSIGNMENT_SUCCESS';
export const TICKET_ASSIGNMENT_FAIL = 'TICKET_ASSIGNMENT_FAIL';

export const TICKET_RESPONS_REQUEST = 'TICKET_RESPONS_REQUSET';
export const TICKET_RESPONS_SUCCESS = 'TICKET_RESPONS_SUCCESS';
export const TICKET_RESPONS_FAIL = 'TICKET_RESPONS_FAIL';

export const TICKET_PRIORITY_REQUEST = 'TICKET_PRIORITY_REQUSET';
export const TICKET_PRIORITY_SUCCESS = 'TICKET_PRIORITY_SUCCESS';
export const TICKET_PRIORITY_FAIL = 'TICKET_PRIORITY_FAIL';

export const TICKET_LIST_REVERSE_REQUEST = 'TICKET_LIST_REVERSE_REQUSET';
export const TICKET_LIST_REVERSE_SUCCESS = 'TICKET_LIST_REVERSE_SUCCESS';
export const TICKET_LIST_REVERSE_FAIL = 'TICKET_LIST_REVERSE_FAIL';
